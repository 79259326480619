// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Highcharts from 'highcharts'
var moment = require('moment')

import $ from 'jquery'
window.jQuery = $
window.$ = $
window.Highcharts = Highcharts

global.$ = global.jQuery = jQuery
// include moment in global and window scope (so you can access it globally)
global.moment = moment
window.moment = moment

import '../src/javascripts/rails'

import '../src/javascripts/bootstrap'

import('../src/javascripts/plugins')
import('../src/javascripts/common')

require('../src/javascripts/are-you-sure')

import('../src/javascripts/companies')
import('../src/javascripts/orders')
